import axios from 'axios';
import config from "@/config";
import {$vfm} from "vue-final-modal";
import AddPaymentMethod from "@/components/Modals/Payment/AddPaymentMethod";
import PaymentMethodSelector from "@/components/Modals/Payment/PaymentMethodSelector";
import ThreeDSecureModal from "@/components/Modals/Payment/ThreeDSecureModal";
import router from "@/router";
import I18n from "@/i18n";



export default {
    state: {
        checkoutInterval: null,
        paymentMethods: [],
        hasWallets: false,
        hasGoogleWallet: false,
        hasAppleWallet: false
    },
    getters: {
        hasGoogleWallet: state => state.hasGoogleWallet,
        hasAppleWallet: state => state.hasAppleWallet
    },
    mutations: {
        SET_CHECKOUT_INTERVAL(state, interval) {
            state.checkoutInterval = interval;
        },
        SET_HAS_WALLETS(state, hasWallets) {
            state.hasWallets = hasWallets;
        },
        SET_GOOGLE_WALLET(state, hasGoogleWallet) {
            state.hasGoogleWallet = hasGoogleWallet;
        },
        SET_APPLE_WALLET(state, hasAppleWallet){
            state.hasAppleWallet = hasAppleWallet;
        }
    },
    actions: {

        async initPaymentWallets({commit}){
            var stripe = Stripe(config.stripe, {
                apiVersion: "2020-08-27",
            });
            const PaymentRequest = await stripe.paymentRequest({
                country: 'FR',
                currency: 'eur',
                total: {
                    label: 'Total',
                    amount: 0,
                },
                requestPayerName: true,
                requestPayerEmail: true,
            });
            const pa = await PaymentRequest.canMakePayment();
            if(pa){
                commit('SET_HAS_WALLETS', pa.googlePay || pa.applePay)
                commit('SET_GOOGLE_WALLET', pa.googlePay)
                commit('SET_APPLE_WALLET', pa.applePay)
            }
            else{
                commit('SET_HAS_WALLETS', false);
            }

            // Test only
            //commit('SET_APPLE_WALLET', true);
            //commit('SET_GOOGLE_WALLET', true);
        },

        async validateCart({getters}){
            const errors = [];
            if(getters.cart.type === 'delivery' && (!getters.cart.cartDelivery || !getters.cart.cartDelivery.addressDefined)){
                errors.push({type: 'address', message: 'Merci de renseigner une adresse de livraison'});
            }
            else if(getters.cart.cartDelivery && !getters.cart.cartDelivery.deliveryAvaible){
                errors.push({type: 'address', message: I18n.t('cart_delivery_not_avaible')});
            }
            if(!getters.merchant.shifts.open && !getters.cart.laterOrder){
                errors.push({type: 'shift', message: I18n.t('cart_merchant_closed_error')});
            }
            if(!getters.user){
                errors.push({type: 'auth', message: 'Non connecté'});
            }
            if(getters.user && !getters.user.phone){
                errors.push({type: 'user', message: 'Pas de numéro de téléphone'});
            }
            if(!getters.conditionsValidated){
                errors.push({type: 'conditions'});
            }
            if(getters.merchant.options.minBaseOrder.value > 0 && getters.cart.type === 'delivery' && getters.merchant.options.minBaseOrder.value > getters.cart.values.totalProduct.value){
                errors.push({type: 'subtotal', message: 'Pour pouvoir commander en livraison, le restaurant a fixé le minimum de commande à '+getters.merchant.options.minBaseOrder.price+'.'});
            }
            return errors;
        },

        /*
        Open payment modal, (choose between "add" or "select" payment method)
        * */
        openPaymentModalAction({getters, dispatch, state}){
            return new Promise(async (resolve, reject) => {
                const errors = await dispatch('validateCart');
                if(errors.length > 0){
                    dispatch('sendStat', {name: 'cart-validation-error'});
                    return reject(errors);
                }
                // Check if user has payment methods
                if(
                    state.hasWallets // If google / apple pay
                    || getters.user.paymentMethods.length > 0 && getters.user.anonymous === false // If payments methods and not anonymous
                    || getters.merchant.options.onsitePaymentEnabled && (getters.cart.type === 'pickup' || getters.cart.type === 'on_site_counter') // If onsite payment enabled
                ){
                    $vfm.show({component: PaymentMethodSelector});
                    dispatch('sendStat', {name: 'cart-payment-begin'});
                    dispatch('eventStartPayment');
                    return resolve(true);
                }
                else {
                    $vfm.show({component: AddPaymentMethod});
                    dispatch('sendStat', {name: 'cart-payment-begin'});
                    dispatch('eventStartPayment');
                    return resolve(true);
                }
            });
        },


        /*
        * Execute onsite payments
        * @params {string} token Order token
        * */
        checkoutOnsitePayment({getters, dispatch}){
            return new Promise(((resolve, reject) => {
                axios.post(config.base_url +'/api/client/v2/checkout/checkoutOnsitePayment', {token:getters.orderToken})
                    .then((response) => {
                        dispatch('eventFinishPayment');
                        $vfm.hideAll();
                        router.push({name: 'checkout', params: {merchantSlug: getters.merchant.slug, orderToken: getters.orderToken}});
                        dispatch('sendStat', {name: 'checkout'});
                        resolve(true);
                    })
                    .catch((error) => {
                        if(error.response.data.message){
                            reject(error.response.data.message);
                        }
                        else{
                            reject('http_'+error.response.status);
                        }
                    })
            }));
        },


        /*
        * Execute new payment method save
        * @params {object} stripe Stripe.js object
        * @params {object} elements Stripe Element objects
        * @params {string} checkoutOrderToken Order token to checkout
        * @params {int} orderId Order id for redirection after checkout
        * */
        checkoutStorePaymentMethodAction({dispatch, getters}, {stripe, elements, checkoutOrderToken, orderId, save=false}){
            return new Promise((async (resolve, reject) => {
                // 1. Create card token
                const cardToken  = await dispatch('createCardTokenWithPaymentMethodAction', {stripe, elements}).catch(e => reject(e));
                if(!cardToken){
                    return;
                }

                // 2. Create setup intent with current payment method
                const data = await dispatch('createPaymentRequestWithCardToken', {token: cardToken.id, checkoutOrderToken:checkoutOrderToken, save}).catch(e => reject(e));
                if(!data){
                    return;
                }
                if(data.success === false){
                    return reject(data.message);
                }

                // 3a. confirm setup intent with 3DS ?
                if(data.next_action){
                    $vfm.hideAll();
                    await dispatch('nextActionAction', {nextAction:data.next_action, orderToken: checkoutOrderToken, orderId:orderId})
                        .then(() => {
                            resolve(true);
                        })
                        .catch(e => reject(e));
                }
                else{
                    // 3b. If no 3DS Requested, redirect now to tracking
                    dispatch('eventFinishPayment');
                    $vfm.hideAll();
                    router.push({name: 'checkout', params: {merchantSlug: getters.merchant.slug, orderToken: checkoutOrderToken}});
                    dispatch('sendStat', {name: 'checkout'});
                    resolve(true);
                }
            }));
        },
        /*
        * Create card token with stripe elements
        * @params {object} stripe Stripe.js object
        * @params {object} elements Stripe Element objects
        * */
        createCardTokenWithPaymentMethodAction({state}, {stripe, elements}) {
            return new Promise(((resolve, reject) => {
                stripe.createToken(elements[0])
                    .then((data) => {
                        if (data.error) {
                            return reject(data.error.code);
                        }
                        resolve(data.token);
                    })
                    .catch(e => reject(e));
            }));
        },

        /*
        * Create payment intent with card token
        * @params {string} token Stripe Payment Method token
        * @params {string} [checkoutOrderToken] Order Token for direct checkout after payment intent
        * */
        createPaymentRequestWithCardToken({state}, {token, checkoutOrderToken, save=false}){
            return new Promise(((resolve, reject) => {
                axios.post(config.base_url +'/api/client/v2/checkout/createPaymentWithCardToken', {token, checkoutOrderToken, save})
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        if(error.response.data.message){
                            reject(error.response.data.message);
                        }
                        else{
                            reject('http_'+error.response.status);
                        }
                    })
            }));
        },


        /*
        * Create payment request for an order with a saved payment method
        * @params {string} paymentMethodToken Stripe Payment Method token
        * @params {string} checkoutOrderToken Order token for checkout
        * */
        checkoutPaymentMethodAction({dispatch, getters}, {paymentMethodToken, checkoutOrderToken, orderId}) {
            return new Promise((async (resolve, reject) => {
                // 1. Create payment intent with saved payment method
                const data = await dispatch('createPaymentRequestWithPaymentMethodToken', {paymentMethodToken, checkoutOrderToken})
                    .catch(e => reject(e));


                // 2. confirm setup intent with 3DS ?
                if(data.next_action){
                    await dispatch('nextActionAction', {nextAction:data.next_action, orderToken: checkoutOrderToken, orderId:orderId})
                        .catch(e => reject(e));
                }
                else{
                    // 3b. If no 3DS Requested, redirect now to tracking
                    dispatch('eventFinishPayment');
                    $vfm.hideAll();
                    router.push({name: 'checkout', params: {merchantSlug: getters.merchant.slug, orderToken: checkoutOrderToken}});
                    dispatch('sendStat', {name: 'checkout'});
                    resolve(true);
                }
            }));
        },

        /*
        * Create payment intent with payment method ID
        * @params {string} token Stripe Payment Method token
        * @params {string} [checkoutOrderToken] Order Token for direct checkout after payment intent
        * */
        createPaymentRequestWithPaymentMethodToken({state}, {paymentMethodToken, checkoutOrderToken}){
            return new Promise(((resolve, reject) => {
                axios.post(config.base_url +'/api/client/v2/checkout/createPaymentWithPaymentMethodToken', {paymentMethodToken, checkoutOrderToken})
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        if(error.response.data.message){
                            reject(error.response.data.message);
                        }
                        else{
                            reject('http_'+error.response.status);
                        }
                    })
            }));
        },

        /*
        * Next action (3DS popup) for setup intent
        * @params {object} Stripe Setup Intent object
        * */
        nextActionAction({dispatch, commit, state, getters}, {nextAction}){
            $vfm.show({component: ThreeDSecureModal, bind: {iframeUrl: nextAction.redirect_to_url.url}})
        },
        check3dsStatusAction({getters}){
            return new Promise(((resolve, reject) => {
                axios.post(config.base_url +'/api/client/v2/checkout/check3dsStatus', {orderToken: getters.cart.token})
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        },





        walletPreRequestAction({commit, getters}, {type}){
            return new Promise(((resolve, reject) => {

                axios.post(config.base_url + '/api/client/v2/checkout/walletPreRequest', {orderToken: getters.orderToken, type: type})
                    .then((response) => {
                        if (response.data.success) {
                            resolve(response.data);
                        } else {
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        },

        walletCompletePaymentAction({getters, dispatch}){
            return new Promise(((resolve, reject) => {
                axios.post(config.base_url + '/api/client/v2/checkout/walletCompletePayment', {orderToken: getters.orderToken})
                    .then((response) => {
                        dispatch('eventFinishPayment');
                        $vfm.hideAll();
                        router.push({name: 'checkout', params: {merchantSlug: getters.merchant.slug, orderToken: getters.orderToken}});
                        dispatch('sendStat', {name: 'checkout'});
                        resolve(true);
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        },

    }
}
