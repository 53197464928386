import config from "@/config";
import Fuse from 'fuse.js'
import moment from "moment";

export default {
    state: {

        filteredMerchants: [],
        categories: [],
        subCategories: [],
        categoriesSlider: [],
        filter: {
            mode: 'near_me',
            type: 'delivery',
            when: 'now',
            whenDay: null,
            whenTimestamp: null,
            whereToken: null,
            category: 'all',
            subCategories: [],
            search: null
        },
        addresses: [],
        addressesFormatted: [],
        isHome: true,
        catalogData: null,
        loading: true,
        merchantLoading: false,
        dateSlots: [],
        timeSlots: [],
        newMerchants: [],
    },
    mutations: {
        SET_CATEGORIES_SLIDER(state, categories){
            return state.categoriesSlider = categories;
        },
        SET_FILTERED_MERCHANT(state, merchants){
            return state.filteredMerchants = merchants;
        },
        SET_FILTER(state, mode){
            state.filter = mode;
        },
        SET_CATEGORIES(state, categories){
            state.categories = categories;
        },
        SET_SUB_CATEGORIES(state, subCategories){
            state.subCategories = subCategories;
        },
        SET_CATALOG_LOADING(state, loading){
            state.loading = loading;
        },
        SET_ADDRESSES(state, addresses){
            state.addresses = addresses;
            localStorage.setItem('addresses', addresses);
        },
        ADD_ADDRESS(state, address){
            const ads = state.addresses;
            ads.push(address);
            state.addresses = ads;
            localStorage.setItem('addresses', ads);
        },
        SET_CATALOG_MERCHANT_LOADING(state, loading){
            state.merchantLoading = loading;
        },
        SET_CATALOG_DATE_SLOTS(state, slots){
            state.dateSlots = slots;
        },
        SET_CATALOG_HOURS_SLOTS(state, slots){
            state.timeSlots = slots;
        },
        SET_CATALOG_DATA(state, data){
            state.catalogData = data;
        },
        SET_NEW_MERCHANT(state, data){
            state.newMerchants = data;
        },
        SET_HOME_SCREEN(state, isHome){
            state.isHome = isHome;
        },
        SET_ADDRESSES_FORMATTED(state, addresses){
            state.addressesFormatted = addresses;
        },
        SET_CATALOG_ADDRESS(state, address){
            state.catalogData.address = address;
        }
    },
    getters: {
        filteredMerchantsAvailable: (state) => {
            let merchants = state.filteredMerchants.filter(s => s.available === true)
            if(state.filter.search !== '' && state.filter.search){
                const fuse = new Fuse(merchants, {
                    keys: ['searchable', 'description', 'name', 'keywords'],
                    includeScore: true,
                    distance: 100,
                    isCaseSensitive: false,
                    threshold: 0.4,
                    shouldSort: true
                })
                merchants = fuse.search(state.filter.search).map(r => r.item);
            }
            return merchants;
        },
        filteredMerchantsUnavailable: (state) => {
            let merchants = state.filteredMerchants.filter(s => !s.available && s.nextOpen)
            if(state.filter.search !== '' && state.filter.search){
                const fuse = new Fuse(merchants, {
                    keys: ['searchable', 'description', 'name', 'keywords'],
                    includeScore: true,
                    distance: 100,
                    isCaseSensitive: false,
                    threshold: 0.4,
                    shouldSort: true
                })
                merchants = fuse.search(state.filter.search).map(r => r.item);
            }

            merchants = merchants.sort((a,b) => {
                return a.nextOpenTimestamp - b.nextOpenTimestamp;
            });

            return merchants;
        },
        categories: state => state.categories,
        categoriesSlider: state => state.categoriesSlider,
        filteredMerchants: state => state.filteredMerchants,
        filter: state => state.filter,
        catalogLoading: state => state.loading,
        catalogMerchantLoading: state => state.merchantLoading,
        catalogDateSlots: state => state.dateSlots,
        catalogTimeSlots: state => state.timeSlots,
        catalogData: state => state.catalogData,
        newMerchants: state => state.newMerchants,
        isInHomeScreen: state => state.isHome,
        subCategories: state => state.subCategories,
        catalogAddressTokens: state => state.addresses,
        catalogAddresses: state => state.addressesFormatted
    },
    actions: {

        getHomeAction({commit}){
            return new Promise(((resolve, reject) => {
                axios.get(config.base_url + '/api/front/v2/home')
                    .then((response) => {
                        if (response.data.success) {
                            commit('SET_NEW_MERCHANT', response.data.new_merchant);
                            commit('SET_SUB_CATEGORIES', response.data.subCategories);
                            resolve(response.data);
                        } else {
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        },

        getUserCurrentPositionAction({commit, dispatch}, {reload=false}){
            return new Promise(((resolve, reject) => {
                navigator.geolocation.getCurrentPosition((position) => {

                        let geocoder = new google.maps.Geocoder();
                        geocoder.geocode({ location: {lat: position.coords.latitude, lng: position.coords.longitude} }, (results, status) => {
                            if(results.length > 0){
                                const e = [];
                                results[0].address_components.forEach((a) => {
                                    e[a.types[0]] = a.long_name;
                                });

                                let address = '';
                                if(e.street_number && e.route){
                                    address = e.street_number + ' ' + e.route;
                                }
                                else if(e.route){
                                    address = e.route;
                                }
                                else if(e.establishment){
                                    address = e.establishment;
                                }

                                dispatch('setCatalogAddressAction', {address: address, city: e.locality, zip: e.postal_code, country: e.country, lat:position.coords.latitude, lng:position.coords.longitude, precise: false, reload: reload})

                                resolve(address + ', ' + e.locality);
                            }
                        });
                },
                (error) => {
                    reject('error_geolocation_'+error.code)
                });
            }));
        },

        getCategoriesSlider({getters, commit}){
            return new Promise(((resolve, reject) => {
                let categories = [];
                getters.filteredMerchants.forEach((cat) => {
                    cat.subCategoriesIds.forEach((id) => {
                        const obj = categories.find(e => e.id === id)
                        if(obj){
                            if(cat.available){
                                obj.sort += 100;
                                obj.open++;
                            }
                            else{
                                obj.sort++;
                            }
                            obj.count++;
                        }
                        else{
                            const cat = getters.subCategories.find(c => c.id === id);
                            if(cat.image.path && cat.important){
                                categories.push({id: id, count:1, sort:0, open: 0, name:cat.name, image: cat.image.path});
                            }
                        }
                    })
                });
                categories = categories.sort(function (a, b) {
                    return b.sort - a.sort;
                });
                commit('SET_CATEGORIES_SLIDER', categories);
                resolve(categories);
            }));

        },


        initCatalogFilterAction({getters, commit}){
            return new Promise(((resolve, reject) => {
                const filter = getters.filter;
                if(localStorage.getItem('catalog_address')){
                    filter.whereToken = localStorage.getItem('catalog_address');
                }
                if(localStorage.getItem('catalog_type')){
                    filter.type = localStorage.getItem('catalog_type');
                }
                if(localStorage.getItem('catalog_when')){
                    filter.when = localStorage.getItem('catalog_when');
                }
                if(localStorage.getItem('catalog_sub_categories')){
                    let subCategories = [];
                    localStorage.getItem('catalog_sub_categories')
                        .split(',')
                        .forEach((e) => {
                        subCategories.push(parseInt(e));
                    })
                    filter.subCategories = subCategories;
                }
                if(localStorage.getItem('catalog_whenDay')){
                    filter.whenDay = localStorage.getItem('catalog_whenDay');
                }
                if(localStorage.getItem('catalog_whenTimestamp')){
                    filter.whenTimestamp = localStorage.getItem('catalog_whenTimestamp');

                    if(filter.whenTimestamp < moment.unix()){
                        filter.when = 'now';
                        filter.whenDay = null;
                        filter.whenTimestamp = null;
                    }
                }

                if(localStorage.getItem('addresses')){
                    commit('SET_ADDRESSES', localStorage.getItem('addresses').split(','))
                }

                if(filter.whereToken === null){
                    commit('SET_HOME_SCREEN', true);
                }
                else{
                    commit('SET_HOME_SCREEN', false);
                }

                commit('SET_FILTER', filter);
                resolve();
            }));
        },

        updateFilter({commit, getters}, values){
            const filter = getters.filter;
            localStorage.setItem('catalog_type', filter.type)
            localStorage.setItem('catalog_sub_categories', filter.subCategories)
            localStorage.setItem('catalog_when', filter.when)
            localStorage.setItem('catalog_address', filter.whereToken)
            if(filter.when !== 'now'){
                localStorage.setItem('catalog_whenDay', filter.whenDay)
                localStorage.setItem('catalog_whenTimestamp', filter.whenTimestamp)
            }
            else{
                localStorage.removeItem('catalog_whenDay');
                localStorage.removeItem('catalog_whenTimestamp');
            }
            const updatedFilter = {...filter, ...values};
            console.log('> Update Filter function :', updatedFilter.subCategories.join(','));
            commit('SET_FILTER', updatedFilter);
        },

        getFilteredMerchantsCatalogAction({commit, getters, state, dispatch}){
            return new Promise(((resolve, reject) => {
                if(state.filter.whereToken === null){
                    console.log('No address, abort search')
                    reject();
                    return;
                }

                commit('SET_CATALOG_MERCHANT_LOADING', true);
                axios.post(config.new_api_url + '/v1/client/marketplace/query/legacy_merchant', {...state.filter, addresses: getters.catalogAddressTokens})
                    .then((response) => {
                        if (response.data.success) {
                            commit('SET_FILTERED_MERCHANT', response.data.merchants);
                            commit('SET_CATEGORIES', response.data.categories);
                            commit('SET_SUB_CATEGORIES', response.data.subCategories);
                            commit('SET_CATALOG_LOADING', false);
                            commit('SET_CATALOG_MERCHANT_LOADING', false);
                            commit('SET_CATALOG_DATA', response.data.catalog);
                            commit('SET_ADDRESSES_FORMATTED', response.data.userAddresses);
                            resolve(response.data);
                        } else {
                            dispatch('resetFiltersAction');
                            commit('SET_HOME_SCREEN', true);
                            dispatch('updateFilter', {whereToken: null});
                            resolve(response.data);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        },

        setHomeAction({commit}){
            commit('SET_HOME_SCREEN', false);
        },

        setFiltersAction({commit, getters, dispatch}, filter){
            commit('SET_FILTER', filter);

            localStorage.setItem('catalog_type', filter.type)
            localStorage.setItem('catalog_sub_categories', filter.subCategories)
            localStorage.setItem('catalog_when', filter.when)
            if(filter.when !== 'now'){
                localStorage.setItem('catalog_whenDay', filter.whenDay)
                localStorage.setItem('catalog_whenTimestamp', filter.whenTimestamp)
            }
            else{
                localStorage.removeItem('catalog_whenDay');
                localStorage.removeItem('catalog_whenTimestamp');
            }
            dispatch('getFilteredMerchantsCatalogAction')
        },

        setSubCategoriesAction({commit, dispatch, getters}, subCategories) {
            let filter = getters.filter;
            filter.subCategories = subCategories;
            localStorage.setItem('catalog_sub_categories', filter.subCategories)
            commit('SET_FILTER', filter);

            dispatch('getFilteredMerchantsCatalogAction')
        },

        setCategoryAction({commit, getters, dispatch}, category){
            const filter = getters.filter;
            if(filter.category === category){
                return;
            }
            else{
                filter.category = category;
            }
            commit('SET_FILTER', getters.filter);
            dispatch('getFilteredMerchantsCatalogAction')
        },

        setModeAction({commit, dispatch, getters}, mode){
            const filter = getters.filter;
            filter.mode = mode;

            commit('SET_FILTER', getters.filter);
            dispatch('getFilteredMerchantsCatalogAction')
        },

        setTypeAction({commit, dispatch, getters}, type){
            const filter = getters.filter;
            filter.type = type;
            localStorage.setItem('catalog_type', type)

            commit('SET_FILTER', getters.filter);
            return dispatch('getFilteredMerchantsCatalogAction')
        },

        setWhereAction({commit, dispatch, getters}, {token, reload=true}){
            const filter = getters.filter;
            filter.whereToken = token;

            commit('SET_FILTER', getters.filter);
            localStorage.setItem('catalog_address', token);

            const add = getters.catalogAddresses.find(e => e.token === token);
            if(add !== -1){
                commit('SET_CATALOG_ADDRESS', add.address+', '+add.city);
            }

            if(reload){
                dispatch('getFilteredMerchantsCatalogAction')
            }
        },

        setWhenAction({commit, dispatch, getters}, {when, date, timestamp}){
            const filter = getters.filter;
            filter.when = when;
            filter.whenTimestamp = timestamp;
            filter.whenDay = date;

            localStorage.setItem('catalog_when', when)
            if(when !== 'now'){
                localStorage.setItem('catalog_whenDay', date)
                localStorage.setItem('catalog_whenTimestamp', timestamp)
            }
            else{
                localStorage.removeItem('catalog_whenDay');
                localStorage.removeItem('catalog_whenTimestamp');
            }

            commit('SET_CATALOG_LOADING', true);
            commit('SET_FILTER', getters.filter);
            return dispatch('getFilteredMerchantsCatalogAction');
        },

        setSearchAction({commit, dispatch, getters}, {search, change}){
            const filter = getters.filter;
            filter.search = search;

            commit('SET_FILTER', getters.filter);
            if(getters.filteredMerchantsAvailable.length === 0
                && getters.filteredMerchantsUnavailable.length === 0
                && change === true
                && filter.category !== 'all'
                && filter.subCategories.length > 0
            ){
                filter.category = 'all';
                filter.subCategories = [];

                commit('SET_FILTER', getters.filter);

                //dispatch('getFilteredMerchantsCatalogAction')
            }
        },


        resetFiltersAction({commit, dispatch, getters}){
            const filter = getters.filter;
            filter.search = null;
            filter.category = 'all';
            filter.subCategories = [];
            localStorage.setItem('catalog_sub_categories', []);

            commit('SET_FILTER', getters.filter);
            dispatch('getFilteredMerchantsCatalogAction')
        },


        getTimeSlotAction({state, commit}, date){
            return new Promise(((resolve, reject) => {
                axios.post(config.base_url + '/api/front/v2/catalog/time/hours', {type: state.filter.type, date: date})
                    .then((response) => {
                        if (response.data.success) {
                            commit('SET_CATALOG_HOURS_SLOTS', response.data.slots);
                            resolve(response.data);
                        } else {
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        },

        getDateSlotAction({commit}){
            return new Promise(((resolve, reject) => {
                axios.post(config.base_url + '/api/front/v2/catalog/time/date')
                    .then((response) => {
                        if (response.data.success) {
                            commit('SET_CATALOG_DATE_SLOTS', response.data.slots);
                            resolve(response.data);
                        } else {
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        },

        setCatalogAddressAction({commit, getters, dispatch}, {address, city, zip, country, lat, lng, reload= true, precise=true}){
            return new Promise(((resolve, reject) => {
                axios.post(config.base_url + '/api/front/v2/catalog/address/set', {address:address, city:city, zip:zip, country:country, lat:lat, lng:lng, precise:precise, addressesToken: getters.catalogAddressTokens})
                    .then((response) => {
                        if (response.data.success) {

                            const filter = getters.filter;
                            filter.whereToken = response.data.catalog.addressToken;
                            localStorage.setItem('catalog_address', response.data.catalog.addressToken);

                            commit('ADD_ADDRESS', response.data.catalog.addressToken);

                            commit('SET_FILTER', getters.filter);
                            if(reload){
                                commit('SET_HOME_SCREEN', false);
                                dispatch('getFilteredMerchantsCatalogAction');
                            }
                            resolve(response.data);
                        } else {
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        },


        copyAddressByTokenAction({commit, dispatch, getters}, {token, reload=true}){
            return new Promise(((resolve, reject) => {

                // if already created, use last token generated.
                if(localStorage.getItem('address_proxy_'+token)){
                    const addressToken = localStorage.getItem('address_proxy_'+token);
                    console.log(token);
                    const filter = getters.filter;
                    filter.whereToken = addressToken;
                    localStorage.setItem('catalog_address', addressToken);
                    commit('ADD_ADDRESS', token);
                    commit('SET_FILTER', getters.filter);
                    if(reload){
                        commit('SET_HOME_SCREEN', false);
                        dispatch('getFilteredMerchantsCatalogAction');
                    }
                    return resolve({token:addressToken});
                }


                axios.post(config.base_url + '/api/front/v2/catalog/address/copy', {token})
                    .then((response) => {
                        localStorage.setItem('address_proxy_'+token, response.data.token);

                        const filter = getters.filter;
                        filter.whereToken = response.data.token;
                        localStorage.setItem('catalog_address', response.data.token);

                        commit('ADD_ADDRESS', response.data.token);
                        commit('SET_FILTER', getters.filter);

                        if(reload){
                            commit('SET_HOME_SCREEN', false);
                            dispatch('getFilteredMerchantsCatalogAction');
                        }
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        },

        parseCatalogAddressAction({commit, getters, dispatch}, {address, reload=true}){
            return new Promise(((resolve, reject) => {
                axios.post(config.base_url + '/api/front/v2/catalog/address/parse', {address:address})
                    .then((response) => {
                        if (response.data.success) {

                            const filter = getters.filter;
                            filter.whereToken = response.data.catalog.addressToken;
                            localStorage.setItem('catalog_address', response.data.catalog.addressToken);

                            commit('ADD_ADDRESS', response.data.catalog.addressToken);

                            commit('SET_FILTER', getters.filter);
                            if(reload){
                                dispatch('getFilteredMerchantsCatalogAction');
                            }

                            resolve(response.data);
                        } else {
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        }

    }
}
