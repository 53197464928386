import config from "@/config";

export default {
    state: {
        checkout: null,
        orderToken: null,
        error: false,
        shareToken: null
    },
    mutations: {
        SET_CHECKOUT(state, checkout){
            return state.checkout = checkout;
        },
        SET_CHECKOUT_ORDER_TOKEN(state, orderToken){
            return state.orderToken = orderToken;
        },
        SET_ERROR(state, error){
            return state.error = error;
        },
        SET_SHARE_TOKEN(state, shareToken){
            return state.shareToken = shareToken;
        },
    },
    getters: {
        checkout: state => state.checkout,
        error: state => state.error,
        shareToken: state => state.shareToken
    },
    actions: {
        loadCheckout({commit, getters}, {merchantSlug, orderToken, shareToken}){
            commit('SET_CHECKOUT_ORDER_TOKEN', orderToken);
            return axios.post(config.base_url + '/api/front/checkout/get', {orderToken:orderToken, merchantSlug:merchantSlug, shareToken:shareToken})
                .then((response) => {
                    commit('SET_CHECKOUT', response.data.checkout);
                    commit('SET_SHARE_TOKEN', response.data.shareToken);
                })
                .catch(() => {
                    if(!getters.checkout){
                        commit('SET_ERROR', true);
                    }
                })

        },
        getShareToken({commit}, {merchantSlug, orderToken}){
            return new Promise((resolve, reject) => {
                axios.post(config.base_url + '/api/front/checkout/share', {orderToken:orderToken, merchantSlug:merchantSlug})
                    .then((response) => {
                        resolve(response.data.share_token);
                    })
                    .catch(error => reject(error));
            });
        }
    }
}
