<template>
  <div class="container">
    <div class="order-validated-screen">


      <svg version="1.1" id="Capa_1" class="svg-fail" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
           viewBox="0 0 511.995 511.995" style="enable-background:new 0 0 511.995 511.995;" xml:space="preserve" width="50px">
        <g>
          <g>
            <path d="M437.126,74.939c-99.826-99.826-262.307-99.826-362.133,0C26.637,123.314,0,187.617,0,256.005
			s26.637,132.691,74.993,181.047c49.923,49.923,115.495,74.874,181.066,74.874s131.144-24.951,181.066-74.874
			C536.951,337.226,536.951,174.784,437.126,74.939z M409.08,409.006c-84.375,84.375-221.667,84.375-306.042,0
			c-40.858-40.858-63.37-95.204-63.37-153.001s22.512-112.143,63.37-153.021c84.375-84.375,221.667-84.355,306.042,0
			C493.435,187.359,493.435,324.651,409.08,409.006z"/>
          </g>
        </g>
        <g>
          <g>
            <path d="M341.525,310.827l-56.151-56.071l56.151-56.071c7.735-7.735,7.735-20.29,0.02-28.046
			c-7.755-7.775-20.31-7.755-28.065-0.02l-56.19,56.111l-56.19-56.111c-7.755-7.735-20.31-7.755-28.065,0.02
			c-7.735,7.755-7.735,20.31,0.02,28.046l56.151,56.071l-56.151,56.071c-7.755,7.735-7.755,20.29-0.02,28.046
			c3.868,3.887,8.965,5.811,14.043,5.811s10.155-1.944,14.023-5.792l56.19-56.111l56.19,56.111
			c3.868,3.868,8.945,5.792,14.023,5.792c5.078,0,10.175-1.944,14.043-5.811C349.28,331.117,349.28,318.562,341.525,310.827z"/>
          </g>
        </g>
      </svg>


      <template v-if="type === 'payment_expired_cart_change'">
        <h1>Paiement expiré</h1>
        <span>Ce paiement a expiré car le montant du paiement n'est plus identique à celui de votre panier.<br> Merci de réessayer avec un autre lien de paiement <br> en contactant le support Delicity au <a href="tel:+33483580648">04 83 58 06 48</a></span>
        <b></b>
      </template>

      <template v-else-if="type === 'insufficient_funds'">
        <h1>Erreur de paiement...</h1>
        <span>Votre banque à refusé de paiement pour cause : <b>fonds insuffisant</b></span>
        <b></b>
      </template>
      <template v-else-if="type === 'setup'">
        <h1>Erreur...</h1>
        <span>Nous n'avons pas réussit à enregistrer votre moyen de paiement.</span>
      </template>

      <template v-else>
        <h1>Erreur de paiement...</h1>
        <span>Vous n'avez pas été débité et votre commande n'est pas envoyée. <br> Veuillez fermer cette page puis réessayez.</span>
      </template>

      <div class="text-center mt-2">
        <a :href="$route.query.retry_url" class="font-weight-bold" v-if="$route.query.retry_url">Cliquez ici pour réessayer</a>
      </div>

    </div>
  </div>
</template>

<style lang="scss">

.order-validated-screen
{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  text-align: center;
  padding: 0 20px;

  h1
  {
    font-weight: 800;
    font-size: 1.7em;
  }
  span
  {

  }
  .svg-fail
  {
    margin-bottom: 1rem;
    path
    {
      fill: #ff1414;
    }
  }
}


</style>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  data() {
    return {
      type: null
    }
  },
  methods: {
    ...mapActions([])
  },
  computed: {
    ...mapGetters([])
  },
  mounted() {
    this.type = this.$route.query.type;
    window.top.postMessage('3DS-authentication-failed');
  }
}
</script>
