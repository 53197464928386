import Vue from 'vue'
import Vuex from 'vuex'

import merchantStore from "@/store/merchantStore";
import orderStore from "@/store/orderStore";
import userStore from "@/store/userStore";
import checkoutStore from "@/store/checkoutStore";
import catalogStore from "@/store/catalogStore";
import uiStore from "@/store/uiStore";
import StatStore from "@/store/StatStore";
import PaymentStore from "@/store/PaymentStore";

Vue.use(Vuex)


export default new Vuex.Store({
  modules: {
    merchant: merchantStore,
    order: orderStore,
    stats: StatStore,
    user: userStore,
    checkout: checkoutStore,
    catalog: catalogStore,
    ui: uiStore,
    PaymentStore: PaymentStore
  }
});
