<template>
  <div class="chatBack">
    <img src="https://delicity.b-cdn.net/front/img/logoquare.png" class="chatLogo">
    <img src="/img/support.png" class="chatBack_text animate__animated animate__bounceIn">
  </div>
</template>

<style>
.chatBack{
  background-image: url('/img/back.jpg');
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: start;
  justify-content: center;
  color: white;
  padding: 20px;
}
.chatLogo
{
  width: 100px;
  margin-top: 20px;
}
.chatBack_text
{
  position: fixed;
  bottom: 80px;
  right: 70px;
  width: 200px;
}
</style>

<script>
import {initCrisp, openCrisp} from "@/crisp";

export default {
  async mounted() {
    await initCrisp();
    openCrisp();
  }
}
</script>
