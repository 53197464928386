<template>
  <svg width="25" height="25" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50H8C8 26.804 26.804 8 50 8C73.196 8 92 26.804 92 50H100Z">
      <animateTransform attributeType="xml"
                        attributeName="transform"
                        type="rotate"
                        from="0 50 50"
                        to="360 50 50"
                        dur="0.5s"
                        repeatCount="indefinite"/>
    </path>
  </svg>
</template>

<script>
export default {
  name: "Loader",
  data() {
    return {}
  },
  methods: {},
  computed: {}
}
</script>
