<template>
  <div  id="app">
    <router-view/>
    <modals-container></modals-container>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import config from "@/config";
import {$vfm} from "vue-final-modal";

export default {
  methods:{
    resizeHeight(){
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      document.documentElement.style.setProperty('--initial-height', `${window.innerHeight}px`);
    },
    hiring(){
      if(!config.isProd){
        return;
      }
      console.log("%c️", "font-size: x-large; font-weight: bold");
      console.log("%cQu'est-ce que tu cherches par ici ? 👀️", "font-size: x-large; font-weight: bold");
      console.log("%c️", "font-size: x-large; font-weight: bold");
      console.log("%cSi tu cherche un nouveau challenge,", "font-size: 16px; font-weight: bold");
      console.log("%cOn recrute des devs pour scaler dans toute la France 🇫🇷 !", "font-size: 16px; font-weight: bold");
      console.log("%c💓 Node.Js / Vuejs / Tailwind / Lambda / MongoDB ...", "font-size: 16px; font-weight: bold")
      console.log("%c️", "font-size: x-large; font-weight: bold");
      console.log("%cRejoins Delicity !️", "color: #00ADEB; font-size: x-large; font-weight: bold");
      console.log("%chttps://t.delicity.co/SHUIXI", "font-size: x-large;");
      console.log("%c️", "font-size: x-large; font-weight: bold");
    }
  },
  components: {
  },
  computed:{
    ...mapGetters([''])
  },
  mounted() {
    window.addEventListener('onorientationchange', this.resizeHeight);
    window.addEventListener('resize', this.resizeHeight);
    window.addEventListener('load', this.resizeHeight);

    document.documentElement.style.setProperty('--initial-height', `${window.innerHeight}px`);
    this.resizeHeight();
    setTimeout(() => {
      this.resizeHeight();
    }, 800);

    if(location.hostname !== 'localhost') {
      this.hiring();
    }


    window.onhashchange = function() {
      if($vfm.openedModals.length > 0){
        $vfm.hideAll();
      }
    }

  }
}
</script>
