<template>
  <div class="container">
    <div class="order-validated-screen">
      <svg version="1.1" class="svg_success" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50px" viewBox="0 0 384.97 384.97" style="enable-background:new 0 0 384.97 384.97;" xml:space="preserve">
        <g>
          <g id="Check_Circle">
            <path d="M192.485,0C86.173,0,0,86.173,0,192.485S86.173,384.97,192.485,384.97c106.3,0,192.485-86.185,192.485-192.485
			C384.97,86.173,298.785,0,192.485,0z M192.485,360.909c-93.018,0-168.424-75.406-168.424-168.424S99.467,24.061,192.485,24.061
			s168.424,75.406,168.424,168.424S285.503,360.909,192.485,360.909z"/>
            <path d="M280.306,125.031L156.538,247.692l-51.502-50.479c-4.74-4.704-12.439-4.704-17.179,0c-4.752,4.704-4.752,12.319,0,17.011
			l60.139,58.936c4.932,4.343,12.307,4.824,17.179,0l132.321-131.118c4.74-4.692,4.74-12.319,0-17.011
			C292.745,120.339,285.058,120.339,280.306,125.031z"/>
          </g>
        </g>
      </svg>
      <h1>{{title}}</h1>
      <span>{{description}}</span>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  data() {
    return {
      title: 'Commande envoyée !',
      description: 'Vous pouvez fermer la page.'
    }
  },
  methods: {
    ...mapActions([]),
  },
  computed: {
    ...mapGetters([])
  },
  mounted() {
    if(this.$route.query.type === 'voucher_success'){
      this.title = "Super !";
      this.description = "Votre carte ticket restaurant a bien été ajoutée.";
    }
    window.top.postMessage('3DS-authentication-complete');
  }
}
</script>




<style lang="scss">
.order-validated-screen
{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  h1
  {
    font-weight: 800;
    font-size: 1.7em;
  }
  span
  {

  }
  .svg_success
  {
    margin-bottom: 1rem;
    path
    {
      fill: #00db15;
    }
  }
}
</style>

