<template>
  <div class="app-page" id="appPage">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ['classes'],
  inheritAttrs: true
}
</script>
