import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios';
import config from "@/config";
import Echo from 'laravel-echo';
import i18n from './i18n'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'keen-ui/dist/keen-ui.css';
import 'animate.css/animate.min.css'

window.Pusher = require('pusher-js');
window.axios = axios;

if (localStorage.getItem('bearer') !== null) {
  axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('bearer')}`;
  //axios.defaults.headers.common.content_type = 'application/x-www-form-urlencoded';

  window.Echo = new Echo({
    broadcaster: 'pusher',
    key: config.pusher_key,
    cluster: 'eu',
    authEndpoint: config.echo_auth_url,
    auth: {headers: {Authorization: `Bearer ${localStorage.getItem('bearer')}`}}
  });
}

Vue.config.productionTip = false;


import VueTelInput from 'vue-tel-input';
Vue.use(VueTelInput);

import { vfmPlugin } from 'vue-final-modal';
Vue.use(vfmPlugin);

import KeenUI from 'keen-ui';
Vue.use(KeenUI);

import VueMeta from 'vue-meta';
Vue.use(VueMeta);

import AppPage from '@/mobileComponents/Page';
import ModalHeader from '@/components/ModalHeader';
import ModalTemplate from '@/components/ModalTemplate';
import Loader from "@/components/Loader";

Vue.component('app-page', AppPage)
Vue.component('modal-template', ModalTemplate);
Vue.component('modal-header', ModalHeader);
Vue.component('loader', Loader);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
