<template>
  <vue-final-modal :name="name"
                   v-bind="$attrs"
                   v-slot="{close}"
                   :classes="['vfm-container vfm-modal-content']"
                   v-on="$listeners">
    <modal-header @close="close" v-if="!noNav">{{title}}</modal-header>
    <slot></slot>
  </vue-final-modal>
</template>

<script>
import ModalHeader from '@/components/ModalHeader.vue';
export default {
  components: {ModalHeader},
  props: {
    noNav:{
      type: Boolean,
      default: false
    },
    title: {
      default: ""
    },
    name: {
      required: true,
      type: String
    }
  }
}
</script>