<template>

  <div class="magiclink-container">
    <div class="magiclink-content">

      <svg width="40" height="40" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_5523_2)">
          <path d="M66.36 30.32L40 56.68L25.64 42.36L20 48L40 68L72 36L66.36 30.32ZM48 8C25.92 8 8 25.92 8 48C8 70.08 25.92 88 48 88C70.08 88 88 70.08 88 48C88 25.92 70.08 8 48 8ZM48 80C30.32 80 16 65.68 16 48C16 30.32 30.32 16 48 16C65.68 16 80 30.32 80 48C80 65.68 65.68 80 48 80Z" fill="currentColor"/>
        </g>
      </svg>
      <span>Vous pouvez revenir sur l'application</span>
    </div>
  </div>

</template>

<style>
.magiclink-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: full;
  background-color: #f5f5f5;
}
.magiclink-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}
.magiclink-content svg{
  color: #0080e9;
  margin-bottom: 10px;
}
.magiclink-content span{
  font-size: 16px;
  font-weight: 600;
  color: #333;
  text-align: center;
}
</style>

<script>
import axios from "axios";
import Config from "../config";

export default {
  data(){
    return {
      isLoading: true
    }
  },
  async mounted() {
    await axios.post(Config.gatewayEndpoint + '/v1/oauth/magic_link/validate', {email: this.$route.query.email, secret: this.$route.query.secretToken})
    this.isLoading = false;
  }
}
</script>
