import config from "@/config";

export default {
    state: {
        merchant: null,
        menu: null,
        isLoading: true,
        merchantError: false,
    },
    mutations: {
        SET_MERCHANT(state, merchant){
            state.merchant = merchant;
        },
        SET_MENU(state, menu){
            state.menu = menu;
        },
        SET_LOADING(state, loading){
            state.isLoading = loading;
        },
        SET_ERROR(state, merchantError){
            state.merchantError = merchantError;
        },
        ADD_ANNOUNCEMENT(state, announcement){
            state.merchant.announcements.push(announcement);
        },
    },
    getters: {
        merchant: state => state.merchant,
        menu: state => JSON.parse(JSON.stringify(state.menu)),
        products: (state) => {
            let products = [];
            if(state.menu){
                state.menu.forEach((cat) => {
                    products = [...products, ...cat.products];
                })
            }
            return products;
        },
        isLoading: state => state.isLoading,
        merchantError: state => state.merchantError,
        shifts: (state) => {
            return state.merchant.shifts.shiftRows.reduce((r, a) => {
                r[a.weekDay] = [...r[a.weekDay] || [], a];
                return r;
            }, {});
        },
        shiftsOnSite: (state) => {
            return state.merchant.shiftsOnSite.shiftRows.reduce((r, a) => {
                r[a.weekDay] = [...r[a.weekDay] || [], a];
                return r;
            }, {});
        }
    },
    actions: {
        getMerchant({state, commit, dispatch}, {slug, type=null}){
            return new Promise(((resolve, reject) => {
                if(state.merchant && state.merchant.slug !== slug){
                    commit('SET_MERCHANT', null);
                    commit('SET_MENU', null);
                    commit('SET_LOADING', true);
                }
                commit('SET_ERROR', false);

                let url = config.base_url + '/api/front/merchant/' + slug
                if(type){
                    url += '?type=' + type;
                }
                axios.get(url)
                    .then((response) => {
                        commit('SET_MERCHANT', response.data.merchant);
                        commit('SET_MENU', response.data.menu);
                        commit('SET_LOADING', false);
                        resolve(response.data.merchant);
                    })
                    .catch((error) => {
                        commit('SET_LOADING', false);
                        commit('SET_ERROR', true);
                        reject(error);
                    });
            }));
        },

        getMenu({commit}, {slug, timestamp}){
            return new Promise(((resolve, reject) => {
                axios.post(config.base_url + '/api/front/merchant/menu', {slug:slug, timestamp:timestamp})
                    .then((response) => {
                        if (response.data.success) {
                            commit('SET_MENU', response.data.menu);
                            resolve(response.data);
                        } else {
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        },

        getHourSlots({commit, state}, {date, type}){
            return new Promise((resolve, reject) => {
                axios.post(config.base_url + '/api/front/get_hour_slot/' + state.merchant.slug, {date:date, type: type})
                    .then((response) => {
                        if(response.data.success){
                            resolve(response.data.slots);
                        }
                    })
                    .catch(() => {
                        reject();
                    })
            })
        },
        getDateSlots({state}){
            return new Promise(((resolve, reject) => {
                axios.post(config.base_url + '/api/front/get_date_slot/' + state.merchant.slug, {})
                    .then((response) => {
                        if (response.data.success) {

                            resolve(response.data);
                        } else {
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        }
    },
};
