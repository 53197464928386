import axios from "axios";
import config from "@/config";

export default {
    actions: {
        sendStat({commit, dispatch, getters}, {name}){
            // OK
        },



        eventAddToCart({commit, getters}, {productId}){
            console.log('✨ Add to cart '+productId);
            if(config.isProd){
                try {
                    let product = getters.products.find(p => p.id = productId)

                    window.fbq('track', 'AddToCart', {
                        content_type: 'product',
                        content_ids: [product.id],
                        content_name: product.name
                    });
                }
                catch (e){
                    console.log('Event error', e);
                }
            }
        },
        eventStartPayment({getters}){
            console.log('✨ Start payment');
            if(config.isProd){
                try{
                    window.fbq('track', 'InitiateCheckout', {
                        value: getters.cart.values.total.value / 100,
                        currency: 'EUR'
                    });
                }
                catch (e){
                    console.log('Event error', e);
                }
            }
        },
        eventSearch(){
            console.log('✨ Search');
            if(config.isProd){
                try{
                    window.fbq('track', 'Search', {search_string: ""});
                }
                catch (e){
                    console.log('Event error', e);
                }
            }
        },
        eventFinishPayment({getters}){
            console.log('✨ Finish payment');
            if(config.isProd){
                try{
                   window.fbq('track', 'Purchase', {
                        delivery_category: getters.cart.type === 'delivery' ? 'home_delivery' : 'in_store',
                        content_type: 'product',
                        content_name: getters.merchant.name,
                        merchant_slug: getters.merchant.slug,
                        contents: getters.cart.OrderProducts.map(item => {
                            return {id: item.product.id, quantity: item.quantity}
                        }),
                        value: getters.cart.values.total.value / 100,
                        currency: 'EUR'
                        }
                    );
                }
                catch (e){
                    console.log('Event error', e);
                }
            }
        }
    }
}
