<template>
  <div class="vfm-modal-header">
    <span><slot></slot></span>
    <button @click="close"><i class="material-icons notranslate">close</i></button>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>
