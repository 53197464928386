<template>
   <vue-final-modal name="3ds" v-bind="$attrs" v-slot="{close}" classes="vfm-container vfm-three-d-secure"  :click-to-close="false" v-on="$listeners">

     <div class="iframe-container">
       <iframe :src="iframeUrl" frameborder="0" class="iframe"></iframe>
     </div>

  </vue-final-modal>
</template>

<style scoped>
.iframe-container
{
  background-color: #fff;
  border-radius: 10px;
}
.iframe{
  height: 700px;
  width: 600px;
  max-width: 95vw;
  max-height: 95vh;
  border-radius: 10px;
}
</style>

<script>
import {mapActions, mapGetters} from "vuex";
import {$vfm} from "vue-final-modal";

export default {
  props: {
    iframeUrl: {
      required: true
    }
  },
  computed: {
    ...mapGetters(['cart', 'merchant'])
  },
  data(){
    return {
      interval: false
    }
  },
  methods: {
    ...mapActions(['check3dsStatusAction', 'sendStat', 'eventFinishPayment']),
  },
  beforeDestroy() {

  },
  mounted() {

    this.interval = setInterval(async () => {
      const check = await this.check3dsStatusAction();
      if(check.status === 'error'){
        $vfm.hide('3ds');
        this.$root.$emit('3DS-authentication-failed', check.error_code);
        this.sendStat({name: '3DS-authentication-failed'});
        clearInterval(this.interval);
      }
      else if(check.status === 'paid'){
        this.eventFinishPayment();
        setTimeout(() => {
          $vfm.hideAll();
          this.$router.push({name: 'checkout', params: {merchantSlug: this.merchant.slug, orderToken: this.cart.token}});
          this.sendStat({name: 'checkout'});
        },500)
        clearInterval(this.interval);
      }
    }, 5000)


    window.addEventListener('message', (ev) => {
      if (ev.data === '3DS-authentication-complete') {
        this.eventFinishPayment();
        setTimeout(() => {
          this.sendStat({name: 'checkout'});
          $vfm.hideAll();
          this.$router.push({name: 'checkout', params: {merchantSlug: this.merchant.slug, orderToken: this.cart.token}});
          clearInterval(this.interval);
        },500)
      }
      if (ev.data === '3DS-authentication-failed'){
        $vfm.hide('3ds');
        this.$root.$emit('3DS-authentication-failed');
        clearInterval(this.interval);
      }
    }, false);

  }
}
</script>
