import config from "@/config";

export default {
    state: {
        orderToken: null,
        userToken: null,
        cart: null,
        isCartLoading: true,
        isCartPriceLoading: false,
        checkout: null,
        conditionsValidated: false
    },
    mutations: {
        SET_CONDITION_VALIDATION(state, value){
            state.conditionsValidated = value
        },
        SET_ORDER_TOKEN(state, token){
            return state.orderToken = token;
        },
        SET_USER_TOKEN(state, token){
            return state.userToken = token;
        },
        SET_CART(state, cart){
            return state.cart = cart;
        },
        SET_CART_COMMENT(state, comment){
            return state.cart.comment = comment;
        },
        SET_CART_LOADING(state, isCartLoading){
            return state.isCartLoading = isCartLoading;
        },
        SET_CART_PRICE_LOADING(state, isCartPriceLoading){
            return state.isCartPriceLoading = isCartPriceLoading;
        },
        SET_CART_PRODUCT_ERROR_BY_CATEGORY(state, {categoryId, error}){
            state.cart.OrderProducts.filter(e => e.categoryId === categoryId).forEach((e) => { e.error = error });
        },
    },
    getters: {
        orderToken: state => state.orderToken,
        userToken: state => state.userToken,
        cart: state => state.cart,
        isCartLoading: state => state.isCartLoading,
        isCartPriceLoading: state => state.isCartPriceLoading,
        conditionsValidated: state => state.conditionsValidated
    },
    actions: {
        createUserToken({commit}){
            if(localStorage.getItem('user_token')){
                return commit('SET_USER_TOKEN', localStorage.getItem('user_token'));
            }

            let token = 'user_' + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
            localStorage.setItem('user_token', token);
            commit('SET_USER_TOKEN', token);
        },

        cartSetErrorByCategory({commit, state, getters}, {categoryId, error}){
            commit('SET_CART_PRODUCT_ERROR_BY_CATEGORY', {categoryId: categoryId, error: error})
        },

        createOrderToken({commit, state}, merchantSlug){
            if(localStorage.getItem('order-'+merchantSlug)){
                let data = JSON.parse(localStorage.getItem('order-'+merchantSlug));
                if(data !== '' && data.merchantSlug === merchantSlug && data.userToken === state.userToken){
                    return commit('SET_ORDER_TOKEN', data.token);
                }
            }
            let order = {
                merchantSlug: merchantSlug,
                userToken: state.userToken,
                init: false,
                token: 'order_' + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
            };
            localStorage.setItem('order-'+merchantSlug, JSON.stringify(order));
            commit('SET_ORDER_TOKEN', order.token);
        },

        resetOrder({commit, dispatch}, {slug, source=null, type=null}){
            localStorage.setItem('order-'+slug, '');
            dispatch('loadOrder', {slug, source, type})
        },



        addToCart({state,commit,dispatch}, {options, productId, quantity, comment, changeDate}){
            commit('SET_CART_LOADING', true);
            let payload = {
                options: options,
                product: productId,
                quantity: quantity,
                comment: comment,
                orderToken:state.orderToken,
                userToken:state.userToken
            };
            return axios.post(config.base_url + '/api/front/order/addToCart', payload)
                .then((response) => {
                    commit('SET_CART', response.data.cart);
                    commit('SET_CART_LOADING', false);

                    dispatch('sendStat', {name: 'add-to-cart'});
                    dispatch('eventAddToCart', {productId});

                    if(changeDate){
                        dispatch('updateMenuAvailability');
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
        },

        updateCartProduct({state,commit,dispatch}, {options, productId, cartProductId, quantity, comment}){
            commit('SET_CART_LOADING', true);
            let payload = {
                options: options,
                product: productId,
                cartProductId: cartProductId,
                quantity: quantity,
                comment: comment,
                orderToken:state.orderToken,
                userToken:state.userToken
            };
            return axios.post(config.base_url + '/api/front/order/updateCart', payload)
                .then((response) => {
                    commit('SET_CART', response.data.cart);
                    commit('SET_CART_LOADING', false);
                    dispatch('sendStat', {name: 'update-cart'});
                });
        },

        removeCart({state,commit,dispatch}, {productId, tid}){
            let payload = {
                product: productId,
                orderToken:state.orderToken,
                userToken:state.userToken,
                tid: tid
            };
            commit('SET_CART_LOADING', true);

            return axios.post(config.base_url + '/api/front/order/removeCart', payload)
                .then((response) => {
                    commit('SET_CART', response.data.cart);
                    dispatch('sendStat', {name: 'remove-cart'});
                    commit('SET_CART_LOADING', false);
                });
        },

        clearNonExclusiveCart({commit, state}, categoryId){
            return new Promise(((resolve, reject) => {
                commit('SET_CART_LOADING', true);

                axios.post(config.base_url + '/api/front/order/clearNonExclusiveCart', {categoryId: categoryId, orderToken:state.orderToken, userToken:state.userToken})
                    .then((response) => {
                        if (response.data.success) {
                            commit('SET_CART', response.data.cart);
                            commit('SET_CART_LOADING', false);
                            resolve(response.data);
                        } else {
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        },


        clearExclusiveCart({commit, state}, categoryId){
            return new Promise(((resolve, reject) => {
                commit('SET_CART_LOADING', true);

                axios.post(config.base_url + '/api/front/order/clearExclusiveCart', {categoryId: categoryId, orderToken:state.orderToken, userToken:state.userToken})
                    .then((response) => {
                        if (response.data.success) {
                            commit('SET_CART', response.data.cart);
                            commit('SET_CART_LOADING', false);
                            resolve(response.data);
                        } else {
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        },

        loadOrder({state, commit, dispatch}, {slug, type=null, source=null}){
            return new Promise(((resolve, reject) => {
                dispatch('createUserToken');
                dispatch('createOrderToken', slug);
                commit('SET_CART_LOADING', true);

                const orderParams = JSON.parse(localStorage.getItem('order-'+slug));

                let init = {
                    toAddressToken: localStorage.getItem('catalog_address'),
                    type: type ? type : localStorage.getItem('catalog_type'),
                    whenTimestamp: localStorage.getItem('catalog_whenTimestamp')
                }
                if(orderParams.init === true){
                    init = {};
                }
                if(type){
                    init.type = type;
                }
                axios.post(config.base_url + '/api/front/order/get', {
                    merchantSlug: slug,
                    userToken: state.userToken,
                    orderToken: state.orderToken,
                    userAgent: navigator.userAgent,
                    source: source,
                    isMobile: mobileCheck(),
                    ...init
                })
                    .then((response) => {
                        orderParams.init = true;

                        if(response.data.cart){
                            localStorage.setItem('order-'+slug, JSON.stringify(orderParams));
                        }

                        if(response.data.error_code === 'order_timeout'){
                            return dispatch('resetOrder', {slug, type, source})
                        }
                        if(response.data.success){
                            commit('SET_CART', response.data.cart);
                        }
                        if(response.data.cart.laterOrder){
                            dispatch('updateMenuAvailability');
                        }
                        commit('SET_CART_LOADING', false);
                        resolve(true);
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        },

        updateMenuAvailability({getters, state, dispatch}){
            let timestamp = 0;
            if(state.cart.laterOrder){
                timestamp = state.cart.laterOrder.startTimestamp;
            }
            else{
                timestamp = state.cart.timestamp;
            }
            dispatch('getMenu', {timestamp:timestamp, slug:getters.merchant.slug});
        },

        addOrderAddress({state, commit, dispatch}, address) {
            commit('SET_CART_LOADING', true);
            return new Promise((resolve, reject) => {
                axios.post(config.base_url + '/api/front/order/addAddress', {address: address, userToken: state.userToken, orderToken: state.orderToken})
                    .then((response) => {
                        if(response.data.success){

                            localStorage.setItem('search_address', address.street_number + ' ' + address.route + ', ' + address.locality);
                            localStorage.setItem('search_address_lat', address.latitude);
                            localStorage.setItem('search_address_lng', address.longitude);

                            commit('ADD_USER_ADDRESS', response.data.address, {root:true});
                            commit('SET_CART', response.data.cart);
                            commit('SET_CART_LOADING', false);
                            resolve(response.data.address);
                        }
                    })
                    .catch(error => reject(error));
            });
        },

        updateAddress({state, commit}, address){
            return new Promise((resolve, reject) => {
                axios.post(config.base_url + '/api/front/order/updateAddress', {address: address, userToken: state.userToken, orderToken: state.orderToken})
                    .then((response) => {
                        commit('SET_USER_ADDRESS', {root: true});
                        resolve(response.data.address)
                    })
                    .catch(error => reject(error));
            });
        },

        setOrderAddress({state, commit, dispatch}, addressId) {
            commit('SET_CART_LOADING', true);
            return axios.post(config.base_url + '/api/front/order/setAddress', {addressId: addressId, orderToken: state.orderToken,  userToken: state.userToken})
                .then((response) => {
                    if(response.data.success){
                        commit('SET_CART', response.data.cart);
                    }
                    commit('SET_CART_LOADING', false);
                }).catch(error => {
                    commit('SET_CART_LOADING', false);
                });
        },

        setOrderTracking({commit, state}, type){
            return axios.post(config.base_url + '/api/front/order/setTracking', {type: type, userToken: state.userToken, orderToken: state.orderToken});
        },

        setOrderType({state, commit, dispatch}, type){
            commit('SET_CART_LOADING', true);
            return axios.post(config.base_url + '/api/front/order/setType', {type: type, userToken: state.userToken, orderToken: state.orderToken})
                .then((response) => {
                    if(response.data.success){
                        commit('SET_CART_LOADING', false);
                        commit('SET_CART', response.data.cart);
                        dispatch('sendStat', {name: 'set-order-type:'+type});
                    }
                });
        },

        setOrderTime({state, commit, dispatch}, {time,laterAtDate,laterAtHour}){
            commit('SET_CART_LOADING', true);
            return new Promise((resolve, reject) => {
                axios.post(config.base_url + '/api/front/order/setTime', {time: time, date: laterAtDate, hour: laterAtHour, userToken: state.userToken, orderToken: state.orderToken})
                    .then((response) => {
                        if(response.data.success){
                            commit('SET_CART_LOADING', false);
                            commit('SET_CART', response.data.cart);
                            dispatch('updateMenuAvailability');
                            resolve();
                        }
                        else{
                            reject(response.data.error);
                        }
                    })
                    .catch((error) => {
                        if(error.response.status === 422){
                            let errors = [];
                            for(let ei in error.response.data.errors){
                                errors.push(error.response.data.errors[ei][0])
                            }
                            reject(errors.join(', '));
                        }
                        reject(error);
                    });
            });

        },

        setOrderComment({state, commit}, comment){
            return axios.post(config.base_url + '/api/front/order/setComment', {comment: comment, userToken: state.userToken, orderToken: state.orderToken})
                .then((response) => {
                    if(response.data.success){
                        commit('SET_CART_COMMENT', comment);
                    }
                });
        },

        setOrderDiscountCode({state, commit}, {code, force}){
            return new Promise((resolve, reject) => {
                axios.post(config.base_url + '/api/front/order/setDiscountCode', {code: code, userToken: state.userToken, orderToken: state.orderToken, force:force})
                    .then((response) => {
                        if(response.data.success){
                            commit('SET_CART', response.data.cart);
                            resolve();
                        }
                        else{
                            reject(response.data.error_code);
                        }
                    })
                    .catch(() => {
                        reject('server_error');
                    });
            });
        },



        removeDiscountCode({state, commit}, discountId){
            return axios.post(config.base_url + '/api/front/order/removeDiscountCode', {discountId: discountId, userToken: state.userToken, orderToken: state.orderToken})
                .then((response) => {
                    commit('SET_CART', response.data.cart);
                });
        },

        checkoutCart({state, commit, dispatch, rootGetters}){
            commit('SET_CART_LOADING', true);

            return new Promise((resolve, reject) => {
                axios.post(config.base_url + '/api/front/checkout/execute', {orderToken: state.orderToken})
                    .then((response) => {
                        if(response.data.success){
                            setTimeout(() => {
                                commit('SET_CHECKOUT', response.data.checkout, { root: true });
                                commit('SET_CHECKOUT_ORDER_TOKEN', state.orderToken, { root: true });
                                commit('SET_SHARE_TOKEN', response.data.shareToken, { root: true });
                                commit('SET_CART', null);
                                //dispatch('resetOrder', {slug: rootGetters.merchant.slug, type: null, });
                            }, 200)
                        }
                        resolve(response.data);
                        commit('SET_CART_LOADING', false);
                    })
                    .catch(error => reject(error));
            });
        },

        updateMeta({commit, state}, {meta}){
            commit('SET_CART_PRICE_LOADING', true);
            return new Promise(((resolve, reject) => {
                axios.post(config.base_url + '/api/front/order/updateMeta', {orderToken: state.orderToken, userToken: state.userToken, merchantOrderMetaId: meta.merchantOrderMetaId, orderMetaId: meta.orderMetaId, selected: meta.selected, quantity:meta.selectedQuantity})
                    .then((response) => {
                        if(response.data.cart){
                            commit('SET_CART', response.data.cart);
                            commit('SET_CART_PRICE_LOADING', false);
                        }
                        if (response.data.success) {
                            resolve(response.data);
                        } else {
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        }

    }
}
