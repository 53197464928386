import moment from "moment";

export default {
    state: {
        modal: [],
        showMobileDownloadBanner: true
    },
    mutations: {
        ADD_OPEN_MODAL(state, name){
            if(state.modal.indexOf(name) === -1){
                state.modal.push(name);
            }
        },
        REMOVE_OPEN_MODAL(state, name){
            if(state.modal.indexOf(name) !== -1){
                state.modal.splice(state.modal.indexOf(name), 1);
            }
        },
        SET_SHOW_MOBILE_DOWNLOAD(state, show){
            state.showMobileDownloadBanner = show;
        }
    },
    getters: {
        modal: state => state.modal,
        showMobileDownloadBanner: state => state.showMobileDownloadBanner
    },
    actions: {
        openModal({commit, state}, name){
            commit('ADD_OPEN_MODAL', name);
        },
        closeModal({commit, getters}, name){
            commit('REMOVE_OPEN_MODAL', name);
        },
        backButton({commit, getters}, {from, to}){

        }
    }
}
