<template>
  <vue-final-modal v-bind="$attrs" v-slot="{close}" classes="vfm-container sm-vfm-container-sheet" content-class="vfm-content vfm-content-small sm-vfm-sheet" v-on="$listeners">
    <modal-header @close="close"  v-if="type === 'apple_pay'">Payer avec Apple Pay</modal-header>
    <modal-header @close="close"  v-if="type === 'google_pay'">Payer avec Google Pay</modal-header>
    <div class="vfm-body p-4">
      <div class="text-center mb-2 text-muted">
        {{cart.values.total.price}}
      </div>

      <apple-pay-button v-if="type === 'apple_pay'"/>
      <google-pay-button v-if="type === 'google_pay'"/>

    </div>
  </vue-final-modal>
</template>

<script>
import {$vfm} from 'vue-final-modal'
import ModalHeader from '@/components/ModalHeader'
import ApplePayButton from "@/components/Wallet/ApplePayButton";
import GooglePayButton from "@/components/Wallet/GooglePayButton";
import {mapGetters} from "vuex";

export default {
  props: {
    type: {
      type: String,
      required: true
    }
  },
  components: {ModalHeader, ApplePayButton, GooglePayButton},
  computed: {
    ...mapGetters(['cart'])
  }
}
</script>
