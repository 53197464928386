<template>
  <vue-final-modal v-bind="$attrs" v-slot="{close}" classes="vfm-container sm-vfm-container-sheet" content-class="vfm-content vfm-content-small sm-vfm-sheet" v-on="$listeners">
    <modal-header @close="close">Payer avec une carte bancaire</modal-header>

    <ui-alert class="mb-0" v-if="errors.some(e => e.error === 'payment')" type="error" :dismissible="false">{{errors.find(e => e.error === 'payment').message}}</ui-alert>

    <div class="p-4 vfm-body d-flex align-items-end">
      <div class="row w-100" style="flex: 1">
        <div class="col-12 mb-3">
          <label class="pm-label">Numéro de carte</label>
          <div id="cardNumber" class="card-field"></div>
          <transition name="fade">
            <span class="pm-error" v-if="errors.some(e => e.error === 'cardNumber')">{{errors.find(e => e.error === 'cardNumber').message}}</span>
          </transition>
        </div>
        <div class="col-6 mb-3">
          <label class="pm-label">Expiration</label>
          <div id="cardExpiry" class="card-field"></div>
          <transition name="fade">
            <span class="pm-error" v-if="errors.some(e => e.error === 'cardExpiry')">{{errors.find(e => e.error === 'cardExpiry').message}}</span>
          </transition>
        </div>
        <div class="col-6 mb-3">
          <label class="pm-label">CVV</label>
          <div id="cardCvc" class="card-field"></div>
          <transition name="fade">
            <span class="pm-error" v-if="errors.some(e => e.error === 'cardCvc')">{{errors.find(e => e.error === 'cardCvc').message}}</span>
          </transition>
        </div>
        <div class="col-12 mt-1" v-if="!user.anonymous">
          <ui-checkbox v-model="saveCard" class="mt-2">Enregistrer ma carte pour mes futurs paiements</ui-checkbox>
        </div>
      </div>
    </div>
    <button class="vfm-action button-shining" :disabled="isLoading" @click="store">
      <span v-if="!isLoading">Payer {{cart.values.total.price}}</span>
      <loader v-else></loader>
    </button>
  </vue-final-modal>
</template>

<style lang="scss" scoped>
.pm-label
{
  font-weight: 600;
  font-size: 14px;
  color: #333;
  margin-bottom: 3px;
}
.pm-error
{
  font-weight: 500;
  font-size: 0.8em;
  color: #e00000;
  line-height: 1.2;
  display: block;
  margin-top: 5px;
}
.card-field
{
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 8px 15px;
  box-shadow: 0 1px 2px rgba(#000,0.07);
  cursor: text;
}
</style>

<script>
import {mapActions, mapGetters} from "vuex";
import config from "@/config";
const style = {base: {fontSize: "16px"}};

export default {
  data(){
    return {
      stripe: null,
      element: null,
      paymentElement: null,
      cardElements: [],
      errors: [],
      isLoading: false,
      saveCard: true
    }
  },
  methods: {
    ...mapActions(['checkoutStorePaymentMethodAction']),
    async init(){
      this.element = await this.stripe.elements();

      const cardNumber = this.element.create('cardNumber', {showIcon: true, style});
      cardNumber.mount(document.getElementById('cardNumber'));
      cardNumber.on('change',(event) => {
        if (event.error !== undefined) {
          this.errors.push({error:'cardNumber', message: event.error.message})
        } else {
          this.errors = this.errors.filter(e => e.error !== 'cardNumber');
        }
      });
      const cardExpiry = this.element.create('cardExpiry', {style});
      await cardExpiry.mount(document.getElementById('cardExpiry'));
      cardExpiry.on('change',(event) => {
        if (event.error !== undefined) {
          this.errors.push({error:'cardExpiry', message: event.error.message})
        } else {
          this.errors = this.errors.filter(e => e.error !== 'cardExpiry');
        }
      });

      const cardCvc = this.element.create('cardCvc', {placeholder: '123', style});
      cardCvc.mount(document.getElementById('cardCvc'));
      cardCvc.on('change',(event) => {
        if (event.error !== undefined) {
          this.errors.push({error:'cardCvc', message: event.error.message})
        } else {
          this.errors = this.errors.filter(e => e.error !== 'cardCvc');
        }
      });
      this.cardElements = [cardNumber, cardExpiry, cardCvc];

    },
    stripeElementUnmount(){
      if(this.cardElements.length > 0){
        this.cardElements.forEach(element => element.unmount())
      }
    },

    async store(){
      this.errors = this.errors.filter(e => e.error !== 'payment');
      this.isLoading = true;

      // First, create a PaymentMethod with the card details.
      await this.checkoutStorePaymentMethodAction({
        stripe: this.stripe,
        elements: this.cardElements,
        checkoutOrderToken:this.cart.token,
        orderId: this.cart.id,
        save: this.user.anonymous === false ? this.saveCard : false
      })
          .catch((e) => {
            this.isLoading = false;
            if(this.$te('errors.'+e)){
              this.errors.push({error:'payment', message: this.$t('errors.'+e)})
            }
            else{
              this.errors.push({error:'payment', message: this.$t('errors.error_please_try_again_code', {code: e})})
            }
          });
    }
  },
  computed: {
    ...mapGetters(['cart', 'user'])
  },
  async mounted() {
    this.stripe = await Stripe(config.stripe);
    this.init();
  }
}
</script>
