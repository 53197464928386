import config from "@/config";
import {toLatLon, toLatitudeLongitude, headingDistanceTo, moveTo, insidePolygon} from 'geolocation-utils'

export default {
    state: {
        bearer: null,
        user: null,
        guessAddress: false,
        account: null,
        enabledLoginMethodsLoading: false,
        enabledLoginMethods: {
            facebook: false,
            google: false,
            apple: true
        }
    },
    mutations: {
        SET_ENABLED_LOGIN_METHODS_LOADING(state, enabledLoginMethodsLoading){
            state.enabledLoginMethodsLoading = enabledLoginMethodsLoading;
        },
        SET_ENABLED_LOGIN_METHODS(state, enabledLoginMethods){
            state.enabledLoginMethods = enabledLoginMethods;
        },
        SET_ACCOUNT(state, account) {
            state.account = account;
        },
        SET_USER_BEARER(state, bearer){
            return state.bearer = bearer;
        },
        SET_USER(state, user){
            return state.user = user;
        },
        SET_USER_ADDRESS(state, address){
            if(!state.user){
                return false;
            }
            return state.user.addresses.find((a) => {
                if(a.id === address.id){
                    a = address;
                }
            })
        },
        ADD_USER_ADDRESS(state, address){
            if(!state.user){
                return false;
            }
            return state.user.addresses.splice(0, 0, address);
        },
        UPDATE_FIRSTNAME(state, firstname){
            return state.user.firstname = firstname;
        },
        UPDATE_LASTNAME(state, lastname){
            return state.user.lastname = lastname;
        },
        UPDATE_ONBOARDING_STATUS(state, onboarding){
            return state.user.onboarding = onboarding;
        },
        UPDATE_PHONE(state, phone){
            return state.user.phone = phone;
        },
        SET_GUESSED_ADDRESS(state, address){
            return state.guessAddress = address;
        }
    },
    getters: {
        enabledLoginMethods: state => state.enabledLoginMethods,
        enabledLoginMethodsLoading: state => state.enabledLoginMethodsLoading,
        bearer: state => state.bearer,
        user: state => state.user,
        addresses: state => state.user.addresses,
        account: state => state.account
    },
    actions: {
        getEnabledLoginMethods({commit, state}){
            commit('SET_ENABLED_LOGIN_METHODS', {
                facebook: false,
                google: false,
                apple: true
            })
            commit('SET_ENABLED_LOGIN_METHODS_LOADING', true)
            return new Promise(((resolve, reject) => {
                axios.post(config.base_url +'/api/mobile/login_methods')
                    .then((response) => {
                        commit('SET_ENABLED_LOGIN_METHODS', response.data.methods);
                        commit('SET_ENABLED_LOGIN_METHODS_LOADING', false)
                    })
                    .catch((error) => {
                        console.log('Request error', error);
                        commit('SET_ENABLED_LOGIN_METHODS_LOADING', false)
                    })
            }));
        },
        getAccessToken({commit, rootState}){
            return axios.post(config.base_url + '/api/front/user/authCheck', {userToken: rootState.order.userToken})
                .then((response) => {
                    if(response.data.success){
                        localStorage.setItem('bearer', response.data.bearer);
                        commit('SET_USER_BEARER', response.data.bearer);
                        axios.defaults.headers.common.Authorization = `Bearer ${ response.data.bearer}`;
                        location.href = location.origin + location.pathname + '?cart=true'
                    }
                })
        },
        checkLogin({commit}){
            if(localStorage.getItem('bearer')){
                commit('SET_USER_BEARER', localStorage.getItem('bearer'));
                return false;
            }
        },
        logout(){
            localStorage.removeItem('bearer');
            location.reload();
        },
        register({commit}, user){
            return new Promise((resolve, reject) => {
                axios.post(config.base_url + '/api/front/user/register', user)
                    .then((response) => {
                        if(response.data.success){
                            resolve(response.data);
                        }
                        else{
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error.response.data.errors);
                    })
            });
        },
        noAccountAction({commit}, user){
            return new Promise((resolve, reject) => {
                axios.post(config.base_url + '/api/front/user/no_account', user)
                    .then((response) => {
                        if(response.data.success){
                            resolve(response.data);
                        }
                        else{
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        reject(error.response.data.errors);
                    })
            });
        },
        loginPassword({commit}, {email, password}){
            let payload = {
                grant_type: 'password',
                client_id: config.auth_client_id,
                client_secret: config.auth_client_secret,
                username: email,
                password: password,
            };
            return axios.post(config.base_url + '/oauth/token', payload)
                .then((response) => {
                    if (response.data.access_token) {
                        localStorage.setItem('bearer', response.data.access_token);
                        localStorage.setItem('refresh_token', response.data.refresh_token);
                        axios.defaults.headers.common.Authorization = `Bearer ${ response.data.access_token}`;
                        commit('SET_USER_BEARER', response.data.access_token);
                        location.href = location.origin + location.pathname + '?cart=true'
                    }
                })
                .catch((error) => {
                    commit('SET_USER_BEARER', null);
                })
        },
        getUser({commit, dispatch, getters}) {
            return new Promise(((resolve, reject) => {
                axios.post(config.base_url + '/api/front/user?merchantSlug='+getters.merchant.slug)
                    .then((response) => {
                        if(response.data.success){
                            commit('SET_USER', response.data.user);


                            if(response.data.user.anonymous === true && getters.merchant.options.anonymousOrder === false){
                                dispatch('logout');
                            }
                        }
                        else{
                            commit('SET_USER', false)
                        }
                        resolve()
                    })
                    .catch((error) => {
                        commit('SET_USER', false)
                        reject(error);
                    })
            }));
        },
        getNearestAddress({state, rootGetters, commit, dispatch}){
            if(rootGetters.cart.cartDelivery && rootGetters.cart.cartDelivery.addressDefined === false && state.user){
                navigator.geolocation.getCurrentPosition((data) => {
                    let currentPosition = {
                        lat: data.coords.latitude,
                        lng: data.coords.longitude
                    };
                    let nearestAddress = null;

                    state.user.addresses.forEach((address) => {
                        let distance = headingDistanceTo(currentPosition, address.position);
                        if(nearestAddress === null || nearestAddress.distance > distance.distance) {
                            nearestAddress = {
                                distance: distance.distance,
                                addressId: address.id,
                                address: address
                            };
                        }
                    });
                    if(nearestAddress){
                        commit('SET_GUESSED_ADDRESS', nearestAddress)
                        dispatch('setOrderAddress', nearestAddress.addressId, {root:true})
                    }
                });
            }
        },
        onboardingAccountSave({state, commit}, {firstname, lastname, phone}){
            return new Promise((resolve, reject) => {
                axios.post(config.base_url + '/api/front/user/update', {firstname: firstname, lastname: lastname, phone:phone})
                    .then((response) => {
                        if(response.data.success){
                            commit('SET_USER', response.data.user)
                            commit('UPDATE_ONBOARDING_STATUS', false);
                            resolve()
                        }
                    })
                    .catch((error) => {
                        let errors = [];
                        let d = error.response.data.errors.phone;
                        d.forEach((i) => {
                            errors.push(i);
                        })
                        reject(errors.join(', '));
                    });
            });
        },
        resetPasswordEmail({commit, rootState}, {email, return_url}){
            return new Promise((resolve, reject) => {
                axios.post(config.base_url + '/api/user/forgot_password', {email:email, return_url:return_url, userToken: rootState.order.userToken})
                    .then((response) => {
                        if(response.data.success){
                            resolve(true);
                        }
                        else{
                            reject(response.data.error_code);
                        }
                    })
                    .catch((error) => {
                        let errors = [];
                        let d = error.response.data.errors.email;
                        d.forEach((i) => {
                            errors.push(i);
                        })
                        reject(errors.join(', '));
                    })
            });
        },

        migrateAnonymousAccount({store}, {email, password}){
            return new Promise(((resolve, reject) => {
                axios.post(config.base_url + '/api/client/v2/account/migrate_anonymous_account', {email, password})
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error.response.data.message);
                    })
            }));
        },
        getAccountAction({commit, state}){
            return new Promise(((resolve, reject) => {
                axios.get(config.base_url + '/api/client/v2/account/get')
                    .then((response) => {
                        commit('SET_ACCOUNT', response.data.account);
                        resolve(response.data.account);
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        }
    }
}
